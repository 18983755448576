<script setup>
    import notFound from '@assets/imgs/404.png';
    import internalError from '@assets/imgs/500.png';
    import i18n from '@/locales';
    const { t } = i18n.global;
    const { $INDEX_ROOT } = useNuxtApp();
    const router = useRouter();
    const error = useError();

    const StatusCode = {
        NOT_FOUND: 404,
        INTERNAL_ERROR: 500,
    };
    const errorMsgMap = {
        [StatusCode.NOT_FOUND]: {
            img: notFound,
            tips: t('global_oops_page_not_found'),
            btn: t('global_back_to_home'),
            func: () => (location.href = location.origin + `/${$INDEX_ROOT}`),
            root: $INDEX_ROOT,
        },
        [StatusCode.INTERNAL_ERROR]: {
            img: internalError,
            tips: t('global_internal_error'),
            btn: t('global_reload'),
            func: () => location.reload(),
        },
    };
    const errorInfo = computed(() => errorMsgMap[error.value?.statusCode || StatusCode.INTERNAL_ERROR]);

    onMounted(() => {
        document.body.setAttribute('class', $INDEX_ROOT);
    });
</script>

<template>
    <NuxtLayout :name="errorInfo.root || 'nofooter'">
        <div class="error-container">
            <img class="error-img" :src="errorInfo.img" alt="" />
            <p class="error-tips">{{ errorInfo.tips }}</p>
            <a href="javascript:;" class="error-btn" @click="errorInfo.func">{{ errorInfo.btn }}</a>
        </div>
    </NuxtLayout>
</template>

<style lang="less">
    @import './assets/styles/index.less';
</style>

<style lang="less" scoped>
    .error-container {
        text-align: center;
        padding-top: 80px;
        height: 100vh;
        .error-img {
            height: 350px;
        }
        .error-tips {
            color: @text-color;
            padding: @padding-xl 0 @padding-md;
        }
        .error-btn {
            display: inline-block;
            background-color: @primary-color;
            color: @white;
            padding: @padding-md-sm @padding-xl;
            user-select: none;
            cursor: pointer;
        }
    }
</style>
